<template>
    <div>
        <div
            class="measure-house-container"
            :style="{ 'min-height': minHeight + 'px' }"
        >
            <div class="measure-house-image">
                <video
                    :src="mainVideo"
                    preload="auto"
                    class="transition-video"
                    muted
                    autoplay
                    playsinline
                >
                    {{ translations["no-support"] }}
                </video>
            </div>

            <div class="measure-house-video" v-if="componentIsReady">
                <video
                    id="videoIn"
                    preload="auto"
                    class="transition-video"
                    :src="videoIn"
                    muted
                    playsinline
                >
                    {{ translations["no-support"] }}
                </video>

                <video
                    id="videoOut"
                    :style="showVideoOut ? { opacity: 1 } : { opacity: 0 }"
                    :src="videoOut"
                    preload="auto"
                    class="transition-video"
                    autoplay
                    muted
                    playsinline
                >
                    {{ translations["no-support"] }}
                </video>

                <video
                    id="loop"
                    v-if="playVideoLoop"
                    :src="videoLoop"
                    preload="auto"
                    class="transition-video"
                    autoplay
                    loop
                    muted
                    playsinline
                >
                    {{ translations["no-support"] }}
                </video>
            </div>

            <transition-group class="did-you-know-dots" name="list" tag="ul">
                <li
                    class=""
                    :key="index"
                    v-for="(marker, index) in activeMarkers"
                    :class="isFading ? 'fadeOut' : 'fadeIn'"
                    v-if="!isFading"
                    :style="{
                        left: marker.left + '%',
                        top: marker.top + '%'
                    }"
                >
                    <a class="dot" :style="{ 'background-color': mainColor }">
                        <div
                            v-if="marker.type === 'tip'"
                            class="icon-wrapper smaller-text"
                        >
                            <b>tip</b>
                        </div>
                        <div v-else class="icon-wrapper">
                            <b>+</b>
                        </div>
                    </a>

                    <div class="information">
                        <h4>{{ marker.tooltipTitle }}</h4>
                        <img v-if="marker.tooltipImage" :src="marker.tooltipImage"/>
                        <p>{{ marker.tooltipDescription }}</p>
                        <a
                            :href="marker.tooltipUrl"
                            class="more-info"
                            :style="{ color: mainColor }"
                        >
                            {{ translations["more-information"] }}
                        </a>
                    </div>
                </li>
            </transition-group>

            <div class="house-arrow">
                <div class="house-arrow-copy">
                    {{ translations["check-possibilities"] }}
                </div>

                <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 256 256"
                    style="enable-background:new 0 0 256 256;"
                    xml:space="preserve"
                >
                    <path
                        d="M254.1,61.9c4.4-21.2-8.5-25.6-12.9-4.3c-13,62.9-91.6,99.5-160.8,96.8c10.6-7.1,20.6-15,29.8-24.4
                	c12.7-13.1,9.4-27.5-8.1-17.2c-26.5,15.7-63.8,20.6-90.3,36.2c-9.7,5.8-12.2,17.4-5.1,26.4c16.2,20.6,42,35.6,60.7,54
                	c15.5,15.3,32.4-2.8,16.9-18.1c-8.2-8.1-16.1-16.3-23.7-24.8C144.5,196.2,236.9,145.3,254.1,61.9z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "../../vueEventBus.js";
import _ from "lodash";

export default {
    computed: {
        activeCategoryToCamelCase: function() {
            return _.camelCase(this.activeCategory);
        },
        activeCategoryToCapitalized: function() {
            return this.activeCategoryToCamelCase.replace(/^\w/, c =>
                c.toUpperCase()
            );
        },
        selectedMarkers: function() {
            if (this.returnVideoDataCategories) {
                return this.returnVideoDataCategories.hotspots.markers;
            }
        },
        returnVideoDataCategories: function() {
            return this.videoData.categories[
                this.activeCategoryToCapitalized
            ];
        }
    },
    data() {
        return {
            activeMarkers: [],
            playVideoIn: true,
            playVideoLoop: false,
            videoIn: "",
            videoOut: "",
            videoLoop: "",
            mainVideo: "",
            isFading: false,
            componentIsReady: false,
            delayedMainColor: "",
            showVideoOut: false,
            minHeight: 800
        };
    },
    mounted() {
        // var vm = this;
        // setTimeout(function() {
        //     console.log(
        //         vm.$el.querySelector(".measure-house-image").offsetHeight
        //     );
        // }, 500);
        this.$emit("change-color", {
            activeIndex: this.activeIndex,
            activeSlug: this.activeSlug
        });

        this.videoIn = this.returnVideoDataCategories
            ? this.returnVideoDataCategories.videoStart
            : "";
        this.videoOut = this.returnVideoDataCategories
            ? this.returnVideoDataCategories.videoStop
            : "";
        this.mainVideo = this.returnVideoDataCategories
            ? this.returnVideoDataCategories.mainVideo
            : "";
        this.startVideoLoop();
        var vm = this;
        this.componentIsReady = true;

        setTimeout(function() {
            vm.activeMarkers = vm.selectedMarkers;
            var vid = vm.$el.querySelector("#videoIn");
            vid.autoplay = true;
            vid.addEventListener("canplaythrough", function() {
                vm.setMarkersHeight();
            });
            vid.load();
        }, 600);
        window.addEventListener("resize", function() {
            vm.setMarkersHeight();
        });
    },
    methods: {
        getMainColor() {
            return this.mainColor;
        },
        startVideoLoop() {
            var vm = this;
            setTimeout(function() {
                vm.videoLoop = vm.returnVideoDataCategories
                    ? vm.returnVideoDataCategories.videoLoop
                    : "";
                vm.playVideoLoop = true;
            }, 1100);
        },
        setMarkersHeight() {
            this.minHeight = this.$el.querySelector("#videoIn").offsetHeight;
        }
    },
    watch: {
        selectedMarkers() {
            this.$emit("change-color", {
                activeIndex: this.activeIndex,
                activeSlug: this.activeSlug
            });

            this.isFading = true;
            this.playVideoLoop = false;

            var vm = this;

            vm.showVideoOut = true;
            setTimeout(function() {
                var vid = vm.$el.querySelector("#videoIn");
                vid.autoplay = false;
                vid.addEventListener("canplaythrough", function() {
                    vm.setMarkersHeight();
                });
                vid.load();
            }, 200);

            setTimeout(function() {
                vm.activeMarkers = vm.selectedMarkers;

                vm.videoOut = vm.returnVideoDataCategories
                    ? vm.returnVideoDataCategories.videoStop
                    : "";

                vm.videoIn = vm.returnVideoDataCategories
                    ? vm.returnVideoDataCategories.videoStart
                    : "";

                var vid = vm.$el.querySelector("#videoIn");
                vid.autoplay = true;
                vid.addEventListener("canplaythrough", function() {
                    vm.setMarkersHeight();
                });
                vid.load();

                vm.showVideoOut = false;

                setTimeout(function() {
                    vm.isFading = false;
                    vm.startVideoLoop();
                }, 2000);
            }, 1200);
        }
    },
    created() {
        EventBus.$on("navigateTo", data => {
            var vm = this;
            setTimeout(function() {
                vm.delayedMainColor = vm.getMainColor();
            }, 1600);
        });
    },
    props: {
        activeCategory: {
            type: String,
            default: ""
        },
        activeIndex: {
            type: Number,
            default: null
        },
        activeSlug: {
            type: String,
            default: null
        },
        mainColor: {
            type: String,
            default: ""
        },
        videoData: {
            type: Object,
            default: {}
        },
        stillImage: {
            type: String,
            default: ""
        },
        translations: {
            type: Object,
            default: {}
        }
    }
};
</script>
