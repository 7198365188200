import { Swiper } from 'swiper/bundle';

/* Tab switcher between listview and mapview in filterbar */
new Swiper('.detail-swiper-container', {
    slidesPerView: 2,
    spaceBetween: 25,
    centeredSlides: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

/* Partner slider on DGM homepage */
new Swiper('.partner-swiper-container', {
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: {
        nextEl: '.partner-swiper-button-next',
        prevEl: '.partner-swiper-button-prev',
    },
    breakpoints: {
        525: {
            slidesPerView: 2,
        },
        769: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 4,
        },
    }
});

/* News slider on DGM */
new Swiper('.news-swiper-container', {
    slidesPerView: 1,
    spaceBetween: 25,
    navigation: {
        nextEl: '.news-swiper-button-next',
        prevEl: '.news-swiper-button-prev',
    },
    pagination: {
        el: '.news-swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        700: {
            slidesPerView: 2,
        },
        1216: {
            slidesPerView: 3,
        },
    }
});

/* Quote slider on DGM */
new Swiper('.quote-swiper-container', {
    slidesPerView: 1,
    navigation: {
        nextEl: '.quote-swiper-button-next',
        prevEl: '.quote-swiper-button-prev',
    },
    pagination: {
        el: '.quote-swiper-pagination',
        clickable: true,
    }
});

/* Measure detail slider on DGM */
new Swiper('.measure-block-swiper-container', {
    slidesPerView: 1,
    navigation: {
        nextEl: '.measure-block-swiper-button-next',
        prevEl: '.measure-block-swiper-button-prev',
    }
});