<template>
    <div class="calculator-item number-style">
        <div>
            <label><b>{{ itemData.name }}</b></label>
            <!-- {{itemData}} -->
        </div>

        <div class="calculator-input">
            <div class="number-container">
                <div class="number-input">
                    <div class="align-vertical">
                        <span class="is-size-3">
                            <img class="calculator-icon-large" :src="itemData.icon"/>
                        </span>

                        <input
                            class="is-size-6"
                            type="number"
                            id=""
                            name=""
                            :min="itemData.min"
                            :max="itemData.max"
                            v-model="valueNumber"
                        />
                        <span class="is-size-6 calculator-unit">
                            <b>{{ itemData.unitSingular }}</b>
                        </span>
                    </div>
                </div>

                <div class="number-buttons">
                    <button
                        type="button"
                        :style="{ 'background-color': mainColor }"
                        class="number-button "
                        @click="changeNumberMinus"
                    >
                        <span class="is-size-4">-</span>
                    </button>

                    <button
                        type="button"
                        :style="{ 'background-color': mainColor }"
                        class="number-button "
                        @click="changeNumberPlus"
                    >
                        <span class="is-size-4">+</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemData: {
            type: Object,
            default: {}
        },
        mainColor: {
            type: String,
            default: '#000000'
        },
        subColor: {
            type: String,
            default: '#FFFFFF'
        }
    },
    computed: {
        valueNumber: {
            set(value) {
                this.$store.dispatch('saveVariableValue', {
                    name: this.itemData.variableName,
                    value
                });
            },
            get() {
                return this.$store.getters['variableValue'](this.itemData.variableName);
            }
        }
    },
    watch: {
        valueNumber(value) {
            this.$emit('change', {
                variableName: this.itemData.variableName,
                value: value
            })
        }
    },
    methods: {
        changeNumberPlus: function () {
            let newValue = parseInt(this.valueNumber) + this.itemData.increase
            if (newValue > this.itemData.max) {
                newValue = this.itemData.max;
            }

            this.$store.dispatch('saveVariableValue', {
                name: this.itemData.variableName,
                value: newValue
            });
        },
        changeNumberMinus: function () {
            let newValue = parseInt(this.valueNumber) - this.itemData.increase
            if (newValue < this.itemData.min) {
                newValue = this.itemData.min;
            }

            this.$store.dispatch('saveVariableValue', {
                name: this.itemData.variableName,
                value: newValue
            });
        }
    },
    mounted() {
        this.$store.dispatch('saveVariableValue', {
            name: this.itemData.variableName,
            value: this.itemData.default
        });
    }
};
</script>
