import { createStore } from 'vuex';
import _ from 'lodash';

// COOKIE LOGIC
function getCookie(name) {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
}

function setCookie(name, value, days) {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function setInitialSavedMeasures() {
    if (document.cookie.length > 0) {
        if (typeof document.cookie != "undefined") {
            return JSON.parse(getCookie("savedmeasures"));
        } else {
            return {};
        }
    } else {
        return {};
    }
}

function setDocumentCookie(input) {
    setCookie("savedmeasures", JSON.stringify(input), 365);
}

// STORE
export const store = createStore({
    state: {
        prepareSaveMeasures: {},
        savedMeasures: setInitialSavedMeasures(),
        variableValues: {}
    },
    getters: {
        savedMeasuresLength: state => {
            var cleanSavedMeasures = _.pickBy(state.savedMeasures, function(value) {
                return !(value === undefined);
            });

            return _.size(cleanSavedMeasures);
        },
        variableValue: (state) => (name) => {
            return state.variableValues[name] || 0
        },
    },
    mutations: {
        saveMeasure(state, input) {
            state.savedMeasures = {
                ...state.savedMeasures,
                ...{ [input.id]: input.content }
            };

            if (typeof state.prepareSaveMeasures[input.id] != "undefined") {
                state.savedMeasures[input.id].calculations =
                    state.prepareSaveMeasures[input.id].calculations;
            } else {
                state.savedMeasures[input.id].calculations = {};
            }

            // set cookie
            setDocumentCookie(state.savedMeasures);
        },
        forgetMeasure(state, input) {
            state.savedMeasures[input] = undefined;
            // set cookie
            setDocumentCookie(state.savedMeasures);
        },
        prepareSaveMeasures(state, input) {
            state.prepareSaveMeasures = {
                ...state.prepareSaveMeasures,
                ...{ [input.id]: input.content }
            };
        },
        resetCalculations(state, input) {
            if (typeof state.savedMeasures[input.id] != "undefined") {
                state.savedMeasures[input.id]["calculations"] =
                    input.calculations;
            }

            // set cookie
            setDocumentCookie(state.savedMeasures);
        },
        saveVariableValue(state, input) {
            state.variableValues = {
                ...state.variableValues,
                ... { [input.name]: input.value }
            }
        }
    },
    actions: {
        prepareSaveMeasures(context, input) {
            context.commit("prepareSaveMeasures", input);

            // IF ITEM IS NOT A SAVED MEASURE THEN (Flip aroud if you want the calculations and cookie to be set whenever you change the inputdata )
            if (context.state.savedMeasures !== null) {
                if (!context.state.savedMeasures[input.id]) {
                    context.commit("resetCalculations", {
                        id: input.id,
                        calculations: input.content.calculations
                    });
                }
            }
        },
        forgetMeasure(context, input) {
            context.commit("forgetMeasure", input);
        },
        saveMeasure(context, input) {
            context.commit("saveMeasure", input);
        },
        saveVariableValue(context, input) {
            context.commit("saveVariableValue", input);
        }
    }
});